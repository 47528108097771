<template>
  <div v-if="!shiftTransactionLoading">
      <report-monthly v-for="(report, index) in shiftTransactionAll" :report-data="report" :report-month="index" :key="index" :class="{'mt-4': index  !== Object.keys(shiftTransactionAll)[0]}" />
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import ReportMonthly from './partials/ReportMonthly.vue';

export default {
  components: { ReportMonthly },
    computed: {
        ...mapState('home', ['sidebarVisible']),
        ...mapState('shift', ['shiftTransactionAll', 'shiftTransactionLoading']),
    },
    methods : {
      ...mapActions('shift', ['getShiftReportTransaction']),
      ...mapMutations('shift', ['SET_SELECTED_SHIFT_TRANSACTION'])
    },
    mounted() {
      
      this.getShiftReportTransaction(
        {
          params: {
              user_id: JSON.parse(localStorage.activeCashier).id
          }
        }
      )

      this.SET_SELECTED_SHIFT_TRANSACTION({})
    }
};
</script>
