<template>
	<div class="report-sidebar position-fixed d-flex flex-column end-0 top-0 bg-white px-3">
		<div
			class="d-flex flex-column h-100 py-4 overflow-auto"
			v-if="Object.keys(selectedShiftTransaction).length "
		>
			<div class="d-flex gap-2 align-items-center">
				<img
					src="@/assets/icons/time-icon.svg"
					alt="time icon"
				>
				<span class="font-14 font-400 font-roboto">{{selectedShiftTransaction.date}}</span>
			</div>
			<el-collapse v-if="selectedShiftTransaction.total_income - selectedShiftTransaction.total_spending >= 0">
				<el-collapse-item name="1">
					<template slot="title">
						<div class="d-flex justify-content-between w-100">
							<span class="font-500 font-14 color-black font-roboto">Pemasukan</span>
							<span class="font-500 font-14 color-black font-roboto me-1">{{formatRupiah(selectedShiftTransaction.total_income, 'Rp. ')}}</span>
						</div>
					</template>
					<div
						class="d-flex justify-content-between"
						v-for="(income, index) in selectedShiftTransaction.payment_method"
						:key="index"
					>
						<template v-if="income">
							<span class=" note font-400 font-14 font-roboto color-black">{{index}}</span>
							<span class="font-400 font-14 font-roboto color-black text-end " style="margin-right: 22px;">{{formatRupiah(income, 'Rp.')}}</span>
						</template>
					</div>

				</el-collapse-item>

			</el-collapse>
			<el-collapse v-if="selectedShiftTransaction.spending.length">
				<el-collapse-item name="1">
					<template slot="title">
						<div class="d-flex justify-content-between w-100">
							<span class="font-500 font-14 color-black font-roboto">Pengeluaran</span>
							<span class="font-500 font-14 color-black font-roboto me-1">{{formatRupiah(selectedShiftTransaction.total_spending, 'Rp. ')}}</span>
						</div>
					</template>
					<div
						class="d-flex w-100  justify-content-between"
						v-for="(spending, index) in selectedShiftTransaction.spending"
						:key="index"
					>
						<span class="note font-400 font-14 font-roboto color-black">{{spending.note}}</span>
						<span class="total font-400 font-14 font-roboto color-black" style="margin-right: 22px;">{{formatRupiah(spending.amount, 'Rp.')}}</span>
					</div>

				</el-collapse-item>

			</el-collapse>
			<div
				class="d-flex mt-2"
				v-if="selectedShiftTransaction.income.length"
			>
				<span class="w-50 font-400 font-14 font-roboto color-black">Pendapatan Bersih</span>
				<span class="w-50 font-700 font-14 font-roboto color-black text-end me-4">{{formatRupiah(selectedShiftTransaction.balance, 'Rp.')}}</span>
			</div>

			<div
				v-if="!selectedShiftTransaction.spending.length && !selectedShiftTransaction.income.length"
				class="w-100 h-100 d-flex align-items-center justify-content-center"
			>
				<p class="text-center mt-3">Tidak ada pemasukan ataupun pengeluaran</p>
			</div>
		</div>
		<div
			class="h-100 d-flex"
			v-else
		>
			<div class="w-100 my-auto">
				<p class="text-center mt-3 color-gray-60">Pilih tanggal untuk menampilkan data</p>
			</div>
		</div>
		<div
			class="mt-auto py-4 w-100"
			v-if="Object.keys(selectedShiftTransaction).length "
		>
			<button
				@click="$router.push('/print-report')"
				class="button button--primary w-100"
				:disabled="!selectedShiftTransaction.spending.length && !selectedShiftTransaction.income.length"
			>
				Export
			</button>
		</div>

	</div>
</template>

<script>
import { mapState } from "vuex";
import IhsMixin from "@/mixins/ihs.mixins.js";

export default {
	mixins: [IhsMixin],
	computed: {
		...mapState("shift", ["selectedShiftTransaction"])
	}
};
</script>

<style lang="scss" scoped>
.note {
	width: 60%;
}
.report-sidebar {
	width: 292px;
	padding-top: 80px;
	height: calc(100%);
}
</style>