<template>
	<div
		ref="popupCustomerForm"
		class="modal fade"
		id="operationalCost"
		tabindex="-1"
		role="dialog"
		aria-labelledby="popupCustomerForm"
		aria-hidden="true"
	>

		<div
			class="
				modal-dialog 
				modal-dialog-centered"
			role="document"
		>

			<div class="modal-content">

				<div class="modal-header">
					<span class="
						font-nunito 
						font-18 
						font-700">
						Biaya Operasional
					</span>
					<button
						type="button"
						ref="closePopupCustomerForm"
						@click="resetForm"
						class="btn-close"
						data-bs-dismiss="modal"
						aria-label="Close"
					></button>

					<button
						ref="triggerClosePopupCustomer"
						type="button"
						class="d-none"
						data-bs-dismiss="modal"
					>
					<button
						ref="triggerOpenPopupCustomer"
						type="button"
						class="d-none"
						data-bs-toggle="modal"
						href="#operationalCost"
						role="button"
					/>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>

				<div class="modal-body">
					<div class="
					font-roboto 
					font-14 
					d-flex 
					flex-column">

						<el-form
							ref="customerForm"
							:model="form"
							:rules="formRules"
							:disabled="loading"
						>
							<div class=" d-flex flex-column">
								<div>
									<span>Jenis</span>
								</div>
								<el-form-item prop="type" class="mb-2 radio-form">
									<span >
										<label
											class="radio"
											id="a-button"
										>
											<input
												name="type"
												type="radio"
												value="income"
												v-model="form.type"
												id="a-button"
												:disabled="loading"
											/>
											<span>Pemasukan</span>
										</label>

										<label
											class="radio ms-3"
											id="b-button"
										>
											<input
												name="type"
												type="radio"
												value="spending"
												v-model="form.type"
												id="b-button"
												:disabled="loading"
											/>
											<span>Pengeluaran</span>
										</label>
									</span>
								</el-form-item>
							</div>

							<div class="d-flex flex-column mt-2">
								<div class="mb-1">
									<span>Jumlah</span>
								</div>
								<el-form-item
									prop="amount"
									class="mb-2"
								>
									<el-input
										v-model="formattedAmount"
										class="w-100"
										:disabled="loading"
									/>
								</el-form-item>
							</div>

							<div class="d-flex flex-column mt-3">
								<div class="mb-1">
									<span>Keterangan</span>
								</div>
								<el-form-item
									prop="note"
									class="mb-2"
								>
									<el-input
										:disabled="loading"
										type="email"
										v-model="form.note"
										class="w-100"
									/>
								</el-form-item>

								<ImageInput  ref="imageInput" class="mt-3" label="Upload Bukti Struk"  @uploadImage="imageUploaded" :input-button-type="isTablet ? 3 : 2" />
								<span class="el-form-item__error position-static" v-if="errorImage.error">{{errorImage.message}}</span>
							</div>

							<div class="d-flex mt-3 mb-3">
								<button
									ref="customerFormSubmit"
									type="button"
									:disabled="loading"
									@click="submitForm()"
									class="btn btn-primary py-2 w-100"
								>
									<span class="font-nunito font-16 font-700 text-white">
										<template v-if="loading">
											<div class="spinner-border small-spinner text-light" role="status">
												<span class="visually-hidden">Loading...</span>
											</div>
										</template>
										<template v-else>
											Simpan Data
										</template>										
									</span>
								</button>
							</div>
						</el-form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import IhsMixin from "@/mixins/ihs.mixins.js";
import ImageInput from "@/components/base/ImageInput.vue"
// import { Modal } from "bootstrap";

export default {
	mixins: [IhsMixin],
	components : {
    ImageInput,
},
	data() {
		const checkAmount = (_, value, callback) => {
			if (parseInt(value.replace(/\D/g, "")) === 0) {
				callback(new Error("Jumlah Pemasukan / Pengeluaran tidak bisa Rp. 0"));
			} else {
				callback();
			}
		};
		return {
			isTablet: window.innerWidth < 1300,
			loading: false,
			form: {
				amount: "Rp. 0",
				type: "",
				note: "",
				image: null
			},
			errorImage : {
				error: false,
				message: 'Tolong Isi Struk'
			},
			formRules: {
				amount: [{ validator: checkAmount, trigger: "change" }],
				type: [
					{
						required: true,
						message: "Tolong Pilih Tipe Biaya Operasional",
						trigger: "change"
					}
				],
				note: [
					{
						required: true,
						message: "Tolong Masukkan Catatan",
						trigger: "change"
					}
				]
			}
		};
	},
	computed: {
		...mapState("shift", ["shiftId"]),
		formattedAmount: {
			get() {
				return this.form.amount;
			},
			// setter
			set(newValue) {
				// Note: we are using destructuring assignment syntax here.
				this.form.amount = this.formatRupiah(newValue, "Rp.");
			}
		}
	},
	methods: {
		...mapActions("user", ["addCustomer", "getCustomer"]),
		...mapActions("cart", ["getCartbyQr", "getCart"]),
		...mapActions("shift", [
			"createShiftTransaction", "getShift"
		]),

		...mapMutations("user", ["SET_SELECTED_CUSTOMER"]),
		imageUploaded(image) {
			if(image) {
				this.errorImage.error = false
			}
			this.form.image = image

			if(this.isTablet) {
				this.openModal()
			}
			
		},
		submitForm() {
			this.$refs.customerFormSubmit.disabled = false;
			this.$refs["customerForm"].validate(valid => {
				if (valid) {
					if (this.form.type === 'spending') {
						if (this.form.image) {
							this.addTransaction();
						} else {
							this.errorImage.error = true
						}
					} else {
						this.addTransaction();

					}
				} else {
					this.$refs.customerFormSubmit.disabled = false;
					return false;
				}
			});
		},
		resetForm() {
			this.form = {
					amount: "Rp. 0",
					type: "",
					note: "",
					image: null
				}
			this.$refs["customerForm"].resetFields();
		},
		async addTransaction() {
			try {
				this.loading = true
				const response = await this.createShiftTransaction({
					data: {
						...this.form,
						amount: this.form.amount.replace(/\D/g, ""),
						shift_id: this.shiftId
					},
					id: this.shiftId
				});
				if (response.data.status_code === 201) {
					this.loading = false
					this.$message({
						showClose: true,
						message: "Berhasil Menambahkan Transaksi",
						type: "success"
					});
					
					this.$emit('addedCost')
					this.resetForm()
					this.$refs["customerForm"].resetFields();
					this.$refs.imageInput.clearImage()
					this.$refs.triggerClosePopupCustomer.click();
					this.getShift()
				}
			} catch (error) {
				this.$refs.triggerClosePopupCustomer.click();
				this.$message({
					showClose: true,
					message: error,
					type: "error"
				});
				this.loading = true
			}
		},
		openModal() {
			this.$refs.triggerClosePopupCustomer.click();
			this.$refs.triggerOpenPopupCustomer.click()

		},
		closeModal() {
			this.$refs.triggerClosePopupCustomer.click();
		}
	},
	mounted() {
		document.getElementById('operationalCost').addEventListener('hidden.bs.modal', function () {
			let backdrop = document.querySelectorAll('.modal-backdrop')
			backdrop.forEach(element => {
					element.remove()
			})
		})

	}
};
</script>

<style lang="scss">
.phoneNumber {
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
	input[type="number"] {
		-moz-appearance: textfield;
	}
	.el-input__prefix {
		margin: auto;
		padding: 0 10px 0 5px;
		display: flex;
	}
	.el-input__inner {
		padding-left: 50px !important;
	}
}

.gender, .radio-form {
	.el-form-item__content {
		line-height: 20px !important;
	}
}
</style>

<style lang="scss" scoped>
.modal-dialog {
	max-width: 570px;
}
.modal-content {
	min-width: 570px;
	min-height: 372px;
}
.modal-body {
	padding-bottom: 0;
}

.custom-gender-buttons {
	display: inline-block;

	label {
		display: inline-block;
		font-size: 16px;
		font-weight: 600;
		line-height: 1.65;
		span {
			display: inline-block;
		}
	}
}

.radio {
	margin: 5px 0 0 0;
	display: inline-block;
	cursor: pointer;
	line-height: 20px !important;
	input {
		display: none;
		&#a-button + span:after {
			border: black 2px solid;
		}
		&#b-button + span:after {
			border: black 2px solid;
		}
		& + span {
			line-height: 15px;
			height: 15px;
			padding-left: 20px;
			position: relative;
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			&:not(:empty) {
				padding-left: 20px;
			}
			&:before,
			&:after {
				content: "";
				width: 16px;
				height: 16px;
				display: block;
				border-radius: 50%;
				left: 0;
				top: 0;
				position: absolute;
			}
			&:before {
				transition: background 0.2s ease,
					transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
			}
			&#a-button:before {
				background: #006cb5;
			}
			&#b-button:before {
				background: #006cb5;
			}
			&:after {
				background: white;
				transform: scale(0.78);
				transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
			}
		}
		&:checked + span {
			&:before {
				transform: scale(1.04);
			}
			&:after {
				transform: scale(0.4);
				transition: transform 0.3s ease;
			}
		}
		&#a-button:checked + span {
			&:before {
				background: white;
				border: 2px solid #006cb5;
			}
			&:after {
				background: #006cb5;
				border: 2px solid #006cb5;
			}
		}
		&#b-button:checked + span {
			&:before {
				background: white;
				border: 2px solid #006cb5;
			}
			&:after {
				background: #006cb5;
				border: 2px solid #006cb5;
			}
		}
	}
	&:hover {
		input {
			& + span {
				&:before {
					transform: scale(0.92);
				}
				&:after {
					transform: scale(0.74);
				}
			}
			&:checked + span {
				&:after {
					transform: scale(0.4);
				}
			}
		}
	}
}
</style>