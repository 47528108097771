<template>
	<div class="d-flex flex-column">
		<div class="d-flex justify-content-between align-items-center">
			<div class="d-flex align-items-center">
				<img
					src="@/assets/icons/date-icon.svg"
					class="date-icon"
					alt="date icon"
				>
				<span class="font-roboto font-400 font-14 ms-2">{{reportMonth}}</span>
			</div>
			<div></div>
			<div class="d-flex align-items-center gap-3">
				<button
					class="button button--white p-2 font-14 font-400 font-roboto"
					@click="exportData(reportMonth, reportData.export)"
				>
					<img
						src="@/assets/icons/export-icon.svg"
						alt=""
					>
					Export
				</button>
				<button
					class="button button--borderless p-0 collapse-arrow " :class="{'show' : collapseShow}"
					@click="toggleCollapse" 
				>
					<img
						src="@/assets/icons/arrow-down-black.svg"
						alt=""
					>
				</button>
			</div>

		</div>
		<div class="day-container row mt-4">
			<div
				class="col-md-4"
				v-for="(report, index) in  Object.fromEntries(Object.entries(reportData.date).splice(0,6))"
				:key="report.date"
			>
				<div
					class="day-container"
					@click="SET_SELECTED_SHIFT_TRANSACTION(report)"
				>
					<input
						type="radio"
						:id="`radio${report.date}`"
						name="radios"
						value="all"
						class="d-none"
					>
					<label
						:for="`radio${report.date}`"
						class="day-item d-flex align-items-center gap-3"
					>
						<div class="day d-flex justify-content-center align-items-center font-700 font-nunito font-16">
							{{index}}
						</div>
						<div class="result d-flex flex-column">
							<span class="font-roboto font-12 font-400">
								Pendapatan
							</span>
							<span class="result-value font-16 font-400 font-roboto">
								{{formatRupiah(report.balance, 'Rp. ')}}
							</span>
						</div>
					</label>
				</div>
			</div>

		</div>
		<div
			class="day-container row mt-3 collapse"
			:id="`${trimmedMonth}`"
		>

			<div
				class="col-md-4"
				v-for="(report, index) in Object.fromEntries(Object.entries(reportData.date).splice(- (Object.keys(reportData.date).length - 6)))"
				:key="report.date"
			>
				<div
					class="day-container"
					@click="SET_SELECTED_SHIFT_TRANSACTION(report)"
				>
					<input
						type="radio"
						:id="`radio${report.date}`"
						name="radios"
						value="all"
						class="d-none"
					>
					<label
						:for="`radio${report.date}`"
						class="day-item d-flex align-items-center gap-3"
					>
						<div class="day d-flex justify-content-center align-items-center font-700 font-nunito font-16">
							{{index}}
						</div>
						<div class="result d-flex flex-column">
							<span class="font-roboto font-12 font-400">
								Pendapatan
							</span>
							<span class="result-value font-16 font-400 font-roboto">
								{{formatRupiah(report.balance, 'Rp. ')}}
							</span>
						</div>
					</label>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import { saveAs } from "file-saver";
import IhsMixin from "@/mixins/ihs.mixins.js";
import { Collapse } from "bootstrap";

export default {
	mixins: [IhsMixin],
	props: {
		reportData: {
			required: true,
			type: Object
		},
		reportMonth: {
			required: true,
			type: String
		}
	},
	data () {
		return {
			collapse: '',
			collapseShow: false
		}
	},
	computed: {
		trimmedMonth() {
			return this.reportMonth.split(" ").join("");
		}
	},
	methods: {
		...mapMutations("shift", ["SET_SELECTED_SHIFT_TRANSACTION"]),
		...mapActions("shift", ["exportShiftTransaction"]),
		toggleCollapse() {
			this.collapse.toggle()
			this.collapseShow = !this.collapseShow
		},
		async exportData(reportMonth, url) {
			const response = await this.exportShiftTransaction(url);
			saveAs(response.data, `Transaction-${reportMonth}.xlsx`);
		}
	},
	mounted() {
		const _this = this
		var monthCollapse = document.getElementById(_this.trimmedMonth)
		this.collapse = new Collapse(monthCollapse, {
				toggle: false
		})
	}
};
</script>

<style lang="scss" scoped>
.day-container {
	row-gap: 20px;
	input[type="radio"]:checked + .day-item {
		border-color: #00b962;
		.day {
			background-color: #00b962;
			color: white;
		}
	}
}
.day-item {
	cursor: pointer;
	border: 2px solid transparent;
	padding: 12px;
	border-radius: 12px;
	background-color: white;

	.day {
		width: 40px;
		height: 42px;
		background-color: #e5f8ef;
		color: #00b962;
		padding: 15px;
		border-radius: 6px;
	}
}

.collapse-arrow {
	transition: 0.3s all;

	img {
		transition: 0.3s all;
	}
	&.show {
		img {
			transform: rotate(180deg);
		}
	}
}
</style>