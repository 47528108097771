<template>
    <div class="modal fade" id="selectImageInput" tabindex="-1" aria-labelledby="selectImageInput" ref="selectImageInput" aria-hidden="true" data-backdrop="static">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <span class="color-blue-dark-50 font-700 font-16">Ambil Gambar dari</span>
                    <div class="d-flex flex-column">
                        <button type="button" class="d-flex align-items-center gap-2 border-0 bg-transparent p-0 mt-2"  @click="selectUpload('camera')">
                            <img src="@/assets/icons/camera-select-icon.svg" alt="select camera">
                            Buka Kamera
                        </button>
                        <button type="button" class="d-flex align-items-center gap-2 border-0 bg-transparent p-0 mt-2" @click="selectUpload('gallery')">
                            <img src="@/assets/icons/gallery-select-icon.svg" alt="select gallery">
                            Pilih Dari Galeri
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap'
export default {
    methods : {
        selectUpload(type) {
            const modal = Modal.getInstance(this.$refs.selectImageInput)
            modal.hide()
            this.$emit('selectUpload', type)
        }
    },
    mounted() {
        document.getElementById('selectImageInput').addEventListener('hidden.bs.modal', function () {
			let backdrop = document.querySelectorAll('.modal-backdrop')
			backdrop.forEach(element => {
					element.remove()
			})
		})
    }
}
</script>